header {
    width: 100%;
    height: 1800px;
    background-image: url('../src/images/fondo.png');
    background-size: cover;
    z-index: 1;
}

.header-menu-new {
    padding-top: 25px;
    height: 200px;
}

.header-logo-new {
    display: block;
    margin: 0 auto;
    width: 100px;
    height: 100px;
    background-color: #0A0A0A;
    border: 2px solid white;
    border-radius: 50%;
}

.header-logo-new:hover {
    background-color: #AFAFAF;
    border: 2px solid #0A0A0A;
    border-radius: 50%;
    transition: 0.3s;
}

.header-social-new-a {
    color: white;
    -webkit-text-stroke: 1.4px black;
}

.header-social-new-li:hover {
    background-color: #AFAFAF;
    margin: 5px;
    padding: 5px;
    border: 1px solid #0A0A0A;
    border-radius: 50%;
}

.header-social-new-a:hover {
    color: #0094FF;
    -webkit-text-stroke: 1.4px black;
}

.header-social-img-new {
    width: 25px;
    height: 25px;
    padding: 1px;
    padding-top: 4px;
}

.header-options-new-li {
    background-color: #0A0A0A;
    margin: 5px;
    padding: 5px;
    border: 1px solid white;
    border-radius: 5px;
    width: 120px;
    transition: 0.3s;
}

.header-options-new-a {
    color: white;
    font-weight: 900;
}

.header-options-new-li:hover {
    background-color: #AFAFAF;
    margin: 5px;
    padding: 5px;
    border: 1px solid #0A0A0A;
    border-radius: 5px;
}

.header-options-new-a:hover {
    color: #0A0A0A;
}

.header-options-new-li-mint {
    background-color: #EFB810;
    margin: 5px;
    padding: 5px;
    border: 1px solid white;
    border-radius: 5px;
    width: 120px;
    transition: 0.3s;
}

.header-options-new-li-mint:hover {
    background-color: #604806;
    margin: 5px;
    padding: 5px;
    border: 1px solid #0A0A0A;
    border-radius: 5px;
}

.option-mint {
    color: black;
    font-weight: 900;
}

.option-mint:hover {
    color: white;
}

.header-animation-left-new {
    background: url('../src/images/header-animation/left-280.png');
    background-size: cover;
    display:inline-block;
    width: 50%;
    margin-top: 100px;
    height: 700px;
}

.header-animation-right-new {
    background: url('../src/images/header-animation/right-280.png');
    background-size: cover;
    display: inline-block;
    width: 50%;
    margin-top: 100px;
    height: 700px;
}

.header-animation-center-new {
    background: url('../src/images/logo2.png');
    background-size: cover;
    position: absolute;
    top: 150px;
    left: 10%;
    width: 350px;
    height: 350px;
    opacity: 0;
    animation-name: dropFly;
	animation-duration: 1.3s;
    animation-direction: alternate;
    animation-iteration-count: infinite;
}

@media (min-width: 0px) {
    .header-social-new {
        position: absolute;
        top: 130px;
        left: 23%;
        font-size: 1em;
        text-align: center;
        z-index: 2;
    }

    .header-social-new-li {
        background-color: #0A0A0A;
        margin: 5px;
        padding: 5px;
        border: 1px solid white;
        border-radius: 50%;
        transition: 0.3s;
    }

    .header-options-new {
        font-size: 1em;
        margin-top: -10px;
        margin-left: 7.5px;
        text-align: center;
        height: 30px;
        z-index: 1;
    }

    .header-animation-new {
        margin-top: 150px;
    }

    .header-animation-left-new {
        background: url('../src/images/header-animation/left-280.png');
        background-size: cover;
    }

    .header-animation-right-new {
        background: url('../src/images/header-animation/right-280.png');
        background-size: cover;
    }

    .header-animation-center-new {
        left: 6%;
        width: 230px;
        height: 230px;
    }
}

@media (min-width: 260px) {
    .header-social-new {
        left: 24.5%;
    }

    .header-animation-center-new {
        left: 7%;
    }
}

@media (min-width: 270px) {
    .header-social-new {
        left: 25%;
    }

    .header-animation-center-new {
        left: 3%;
        width: 260px;
        height: 260px;
    }
}

@media (min-width: 280px) {
    .header-social-new {
        left: 26%;
    }

    .header-animation-center-new {
        left: 4%;
        width: 270px;
        height: 270px;
    }
}

@media (min-width: 290px) {
    .header-social-new {
        left: 27%;
    }

    .header-animation-center-new {
        left: 3%;
        width: 280px;
        height: 280px;
    }
}

@media (min-width: 300px) {
    .header-social-new {
        left: 28%;
    }

    .header-animation-center-new {
        left: 3%;
        width: 290px;
        height: 290px;
    }
}

@media (min-width: 310px) {
    .header-animation-center-new {
        left: 4%;
        width: 290px;
        height: 290px;
    }
}

@media (min-width: 320px) {
    .header-social-new {
        left: 29%;
    }

    .header-animation-center-new {
        left: 3%;
        width: 310px;
        height: 310px;
    }
}

@media (min-width: 330px) {
    .header-social-new {
        left: 30%;
    }

    .header-animation-center-new {
        left: 3%;
        width: 320px;
        height: 320px;
    }
}

@media (min-width: 340px) {
    .header-social-new {
        left: 31%;
    }

    .header-animation-center-new {
        left: 3%;
        width: 330px;
        height: 330px;
    }
}

@media (min-width: 350px) {
    .header-animation-center-new {
        left: 3%;
        width: 340px;
        height: 340px;
    }
}

@media (min-width: 360px) {
    .header-social-new {
        left: 32%;
    }

    .header-animation-center-new {
        left: 3%;
        width: 350px;
        height: 350px;
    }
}

@media (min-width: 370px) {
    .header-animation-center-new {
        left: 3%;
        width: 360px;
        height: 360px;
    }
}

@media (min-width: 380px) {
    .header-social-new {
        left: 33%;
    }

    .header-animation-center-new {
        left: 3%;
        width: 370px;
        height: 370px;
    }
}

@media (min-width: 390px) {
    .header-social-new {
        left: 33.1%;
    }

    .header-animation-center-new {
        left: 3%;
        width: 380px;
        height: 380px;
    }
}

@media (min-width: 400px) {
    .header-animation-center-new {
        left: 3%;
        width: 390px;
        height: 390px;
    }
}

@media (min-width: 410px) {
    .header-social-new {
        left: 33.5%;
    }

    .header-animation-center-new {
        left: 3%;
        width: 400px;
        height: 400px;
    }
}

@media (min-width: 420px) {
    .header-social-new {
        left: 33.75%;
    }

    .header-animation-center-new {
        left: 3%;
        width: 410px;
        height: 410px;
    }
}

@media (min-width: 430px) {
    .header-social-new {
        left: 34.25%;
    }

    .header-animation-center-new {
        left: 3%;
        width: 420px;
        height: 420px;
    }
}

@media (min-width: 440px) {
    .header-social-new {
        left: 34.45%;
    }

    .header-animation-center-new {
        left: 3%;
        width: 430px;
        height: 430px;
    }
}

@media (min-width: 450px) {
    .header-social-new {
        left: 34.75%;
    }

    .header-animation-center-new {
        left: 3%;
        width: 440px;
        height: 440px;
    }
}

@media (min-width: 460px) {
    .header-social-new {
        left: 35%;
    }

    .header-animation-center-new {
        left: 3%;
        width: 450px;
        height: 450px;
    }
}

@media (min-width: 470px) {
    .header-social-new {
        left: 35.5%;
    }

    .header-animation-center-new {
        left: 3%;
        width: 460px;
        height: 460px;
    }
}

@media (min-width: 480px) {
    .header-animation-left-new {
        background: url('../src/images/header-animation/left-480.png');
        background-size: cover;
    }

    .header-animation-right-new {
        background: url('../src/images/header-animation/right-480.png');
        background-size: cover;
    }

    .header-social-new {
        left: 35.75%;
    }

    .header-animation-center-new {
        left: 3%;
        width: 470px;
        height: 470px;
    }
}

@media (min-width: 490px) {
    .header-social-new {
        left: 36.25%;
    }

    .header-animation-center-new {
        left: 3%;
        width: 480px;
        height: 480px;
    }
}

@media (min-width: 500px) {
    .header-social-new {
        left: 36.5%;
    }

    .header-animation-center-new {
        left: 3%;
        width: 490px;
        height: 490px;
    }
}

@media (min-width: 510px) {
    .header-animation-center-new {
        left: 3%;
        width: 500px;
        height: 500px;
    }
}

@media (min-width: 520px) {
    .header-social-new {
        left: 36.9%;
    }

    .header-animation-center-new {
        left: 2%;
    }
}

@media (min-width: 540px) {
    .header-social-new {
        left: 37.5%;
    }

    .header-animation-center-new {
        left: 4%;
    }
}

@media (min-width: 550px) {
    .header-animation-center-new {
        left: 5%;
    }
}

@media (min-width: 560px) {
    .header-social-new {
        left: 37.8%;
    }

    .header-animation-center-new {
        left: 6%;
    }
}

@media (min-width: 570px) {
    .header-social-new {
        left: 38%;
    }

    .header-animation-center-new {
        left: 7%;
    }
}

@media (min-width: 580px) {
    .header-animation-left-new {
        background: url('../src/images/header-animation/left-580.png');
        background-size: cover;
    }

    .header-animation-right-new {
        background: url('../src/images/header-animation/right-580.png');
        background-size: cover;
    }

    .header-social-new {
        left: 38.25%;
    }

    .header-animation-center-new {
        left: 8%;
    }
}

@media (min-width: 590px) {
    .header-social-new {
        left: 38.5%;
    }

    .header-animation-center-new {
        left: 8.5%;
    }
}

@media (min-width: 600px) {
    .header-social-new {
        left: 38.75%;
    }

    .header-animation-center-new {
        left: 9%;
    }
}

@media (min-width: 610px) {
    .header-social-new {
        left: 38.8%;
    }

    .header-animation-center-new {
        left: 10%;
    }
}

@media (min-width: 620px) {
    .header-social-new {
        left: 39%;
    }

    .header-animation-center-new {
        left: 11%;
    }
}

@media (min-width: 630px) {
    .header-social-new {
        left: 39.25%;
    }

    .header-animation-center-new {
        left: 12%;
    }
}

@media (min-width: 640px) {
    .header-social-new {
        left: 39.35%;
    }

    .header-animation-center-new {
        left: 13%;
    }
}

@media (min-width: 650px) {
    .header-social-new {
        left: 39.5%;
    }
}

@media (min-width: 660px) {
    .header-social-new {
        left: 39.55%;
    }
}

@media (min-width: 670px) {
    .header-social-new {
        left: 39.75%;
    }
}

@media (min-width: 680px) {
    .header-animation-left-new {
        background: url('../src/images/header-animation/left-680.png');
        background-size: cover;
    }

    .header-animation-right-new {
        background: url('../src/images/header-animation/right-680.png');
        background-size: cover;
    }

    .header-social-new {
        left: 39.9%;
    }

    .header-animation-center-new {
        left: 14%;
    }
}

@media (min-width: 690px) {
    .header-social-new {
        left: 40.25%;
    }
}

@media (min-width: 700px) {
    .header-animation-center-new {
        left: 15%;
    }
}

@media (min-width: 710px) {
    .header-social-new {
        left: 40.25%;
    }
}

@media (min-width: 720px) {
    .header-social-new {
        left: 40.5%;
    }

    .header-animation-center-new {
        left: 16%;
    }
}

@media (min-width: 730px) {
    .header-social-new {
        left: 40.75%;
    }

    .header-animation-center-new {
        left: 16%;
    }
}

@media (min-width: 740px) {
    .header-animation-center-new {
        left: 16%;
    }
}

@media (min-width: 750px) {
    .header-social-new {
        left: 41%;
    }

    .header-animation-center-new {
        left: 17%;
    }
}

@media (min-width: 770px) {
    .header-social-new {
        left: 41.25%;
    }

    .header-animation-center-new {
        left: 18%;
    }
}

@media (min-width: 780px) {
    .header-animation-left-new {
        background: url('../src/images/header-animation/left-780.png');
        background-size: cover;
    }

    .header-animation-right-new {
        background: url('../src/images/header-animation/right-780.png');
        background-size: cover;
    }

    .header-animation-center-new {
        left: 19%;
    }
}

@media (min-width: 790px) {
    .header-social-new {
        left: 41.5%;
    }

    .header-animation-center-new {
        left: 19%;
    }
}

@media (min-width: 800px) {
    .header-animation-center-new {
        left: 21%;
    }
}

@media (min-width: 810px) {
    .header-social-new {
        left: 41.6%;
    }

    .header-animation-center-new {
        left: 20%;
    }
}

@media (min-width: 820px) {
    .header-social-new {
        left: 41.75%;
    }
}

@media (min-width: 840px) {
    .header-animation-center-new {
        left: 21%;
    }
}

@media (min-width: 850px) {
    .header-social-new {
        left: 41.85%;
    }

    .header-animation-center-new {
        left: 22%;
    }
}

@media (min-width: 870px) {
    .header-social-new {
        left: 42%;
    }
}

@media (min-width: 880px) {
    .header-social-new {
        left: 42.15%;
    }

    .header-animation-center-new {
        left: 23%;
    }
}

@media (min-width: 890px) {
    .header-social-new {
        left: 42.25%;
    }
}

@media (min-width: 900px) {
    .header-animation-left-new {
        background: url('../src/images/header-animation/left-900.png');
        background-size: cover;
    }

    .header-animation-right-new {
        background: url('../src/images/header-animation/right-900.png');
        background-size: cover;
    }
}

@media (min-width: 910px) {
    .header-social-new {
        left: 42.45%;
    }
}

@media (min-width: 920px) {
    .header-social-new {
        left: 42.45%;
    }
}

@media (min-width: 930px) {
    .header-social-new {
        left: 42.55%;
    }
}

@media (min-width: 940px) {
    .header-animation-center-new {
        left: 23%;
    }
}

@media (min-width: 960px) {
    .header-social-new {
        left: 42.75%;
    }
}

@media (min-width: 970px) {
    .header-animation-center-new {
        left: 25%;
    }
}

@media (min-width: 980px) {
    .header-social-new {
        left: 42.85%;
    }
}

@media (min-width: 990px) {
    .header-social-new {
        left: 43%;
    }
}

@media (min-width: 1000px) {
    .header-animation-left-new {
        background: url('../src/images/header-animation/left-1000.png');
        background-size: cover;
    }

    .header-animation-right-new {
        background: url('../src/images/header-animation/right-1000.png');
        background-size: cover;
    }
}

@media (min-width: 1010px) {
    .header-social-new {
        left: 43%;
    }
}

@media (min-width: 1030px) {
    .header-social-new {
        left: 43.25%;
    }
}

@media (min-width: 1050px) {
    .header-animation-center-new {
        left: 26%;
    }
}

@media (min-width: 1060px) {
    .header-social-new {
        left: 43.5%;
    }
}

@media (min-width: 1070px) {
    .header-animation-center-new {
        left: 27%;
    }
}

@media (min-width: 1090px) {
    .header-social-new {
        left: 43.75%;
    }
}

@media (min-width: 1100px) {
    .header-animation-left-new {
        background: url('../src/images/header-animation/left-1100.png');
        background-size: cover;
    }

    .header-animation-right-new {
        background: url('../src/images/header-animation/right-1100.png');
        background-size: cover;
    }
}

@media (min-width: 1130px) {
    .header-social-new {
        left: 43.85%;
    }
}

@media (min-width: 1150px) {
    .header-animation-center-new {
        left: 29%;
    }
}

@media (min-width: 1160px) {
    .header-social-new {
        left: 44.25%;
    }
}

@media (min-width: 1200px) {
    .header-logo-new {
        margin-left: 30px;
    }

    .header-options-new {
        margin-top: -150px;
    }

    .header-social-new {
        top: 45px;
        left: 88%;
    }

    .header-social-new-li {
        display: inline-block;
    }

    .header-animation-left-new {
        background: url('../src/images/header-animation/left-1200.png');
        background-size: cover;
    }

    .header-animation-right-new {
        background: url('../src/images/header-animation/right-1200.png');
        background-size: cover;
    }
}

@media (min-width: 1210px) {
    .header-animation-center-new {
        left: 30%;
    }
}

@media (min-width: 1300px) {
    .header-animation-left-new {
        background: url('../src/images/header-animation/left-1300.png');
        background-size: cover;
    }

    .header-animation-right-new {
        background: url('../src/images/header-animation/right-1300.png');
        background-size: cover;
    }

    .header-animation-center-new {
        left: 31%;
    }
}

@media (min-width: 1360px) {
    .header-animation-center-new {
        left: 32%;
    }
}

@media (min-width: 1400px) {
    .header-animation-left-new {
        background: url('../src/images/header-animation/left-1400.png');
        background-size: cover;
    }

    .header-animation-right-new {
        background: url('../src/images/header-animation/right-1400.png');
        background-size: cover;
    }

    .header-animation-center-new {
        left: 33%;
    }
}

@media (min-width: 1500px) {
    .header-animation-left-new {
        background: url('../src/images/header-animation/left-1500.png');
        background-size: cover;
    }

    .header-animation-right-new {
        background: url('../src/images/header-animation/right-1500.png');
        background-size: cover;
    }

    .header-animation-center-new {
        left: 34%;
    }
}

@media (min-width: 1600px) {
    .header-animation-left-new {
        background: url('../src/images/header-animation/left-1600.png');
        background-size: cover;
    }

    .header-animation-right-new {
        background: url('../src/images/header-animation/right-1600.png');
        background-size: cover;
    }

    .header-animation-center-new {
        left: 35%;
    }
}

@media (min-width: 1700px) {
    .header-animation-left-new {
        background: url('../src/images/header-animation/left-1700.png');
        background-size: cover;
    }

    .header-animation-right-new {
        background: url('../src/images/header-animation/right-1700.png');
        background-size: cover;
    }

    .header-animation-center-new {
        left: 36%;
    }
}

@media (min-width: 1800px) {
    .header-animation-left-new {
        background: url('../src/images/header-animation/left-1800.png');
        background-size: cover;
    }

    .header-animation-right-new {
        background: url('../src/images/header-animation/right-1800.png');
        background-size: cover;
    }

    .header-animation-center-new {
        left: 37%;
    }
}

@media (min-width: 1900px) {
    .header-animation-left-new {
        background: url('../src/images/header-animation/left-1900.png');
        background-size: cover;
    }

    .header-animation-right-new {
        background: url('../src/images/header-animation/right-1900.png');
        background-size: cover;
    }
    .header-animation-center-new {
        left: 38%;
    }
}

@media (min-width: 2000px) {
    .header-animation-left-new {
        background: url('../src/images/header-animation/left-2000.png');
        background-size: cover;
    }

    .header-animation-right-new {
        background: url('../src/images/header-animation/right-2000.png');
        background-size: cover;
    }

    .header-animation-center-new {
        left: 39%;
    }
}

@media (min-width: 2100px) {
    .header-animation-left-new {
        background: url('../src/images/header-animation/left-2100.png');
        background-size: cover;
    }

    .header-animation-right-new {
        background: url('../src/images/header-animation/right-2100.png');
        background-size: cover;
    }

    .header-animation-center-new {
        left: 40%;
    }
}

@media (min-width: 2300px) {
    .header-animation-left-new {
        background: url('../src/images/header-animation/left-2300.png');
        background-size: cover;
    }

    .header-animation-right-new {
        background: url('../src/images/header-animation/right-2300.png');
        background-size: cover;
    }
}

@media (min-width: 2500px) {
    .header-animation-left-new {
        background: url('../src/images/header-animation/left-2500.png');
        background-size: cover;
    }

    .header-animation-right-new {
        background: url('../src/images/header-animation/right-2500.png');
        background-size: cover;
    }
}

/* ANIMATIONS */

@keyframes dropFly {
	0%{
        transform: translateY(0.5px);
    }
    5%{
        transform: translateY(1px);
    }
    10%{
        transform: translateY(1.5px);
    }
    15%{
        transform: translateY(2px);
    }
    20%{
        transform: translateY(2.5px);
    }
    25%{
        transform: translateY(3px);
    }
    30%{
        transform: translateY(3.5px);
    }
    35%{
        transform: translateY(4px);
    }
    40%{
        transform: translateY(4.5px);
    }
    45%{
        transform: translateY(5px);
    }
    50%{
        transform: translateY(5.5px);
    }
    55%{
        transform: translateY(6px);
    }
    60%{
        transform: translateY(6.5px);
    }
    65%{
        transform: translateY(7px);
    }
    70%{
        transform: translateY(7.5px);
    }
    75%{
        transform: translateY(8px);
    }
    80%{
        transform: translateY(8.5px);
    }
    85%{
        transform: translateY(9px);
    }
    90%{
        transform: translateY(9.5px);
    }
    95%{
        transform: translateY(10px);
    }
    100%{
        transform: translateY(10.5px);
    }
}